<template>
    <div>这是第三方开发的项目部署应用
    <el-button @click="hello">感谢使用</el-button>
    </div>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        isBg: true,
        isLoading: false,
        isLogin: false,
        isDeskTop: false
      }
    },

    created() {

    },
    methods: {
      hello() {
        alert("Hello11")
      }
    }
  }
</script>
