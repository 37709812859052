import {createApp} from "vue"
import MacOS from "./MacOS"


const macOS = createApp(MacOS)


import ElementPlus from "element-plus"
import "element-plus/dist/index.css"
import zhCn from "element-plus/es/locale/lang/zh-cn"

macOS.use(ElementPlus, {
  locale: zhCn
})



console.log("Micro App1", macOS)

export const provider = () => ({
  // render 渲染函数，必须提供
  render: ({dom, basename, appName, props}) => {
    console.log("basename", basename, "appName", appName, "props", props)

    if (props.component) {
      props.component.setTitle("第三方自动化部署系统")
      props.component.setRect({width: 600, height: 200})
    }

    macOS.mount(dom)
  },
  // destroy 应用销毁函数，必须提供
  destroy: ({dom, basename}) => {
    console.log("destroy basename", basename)
    console.log("dom", dom)
    macOS.unmount()
  }
})
